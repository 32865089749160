@charset "UTF-8";
@import 'node_modules/modern-normalize/modern-normalize';
@import './mixin';
@import './setting';
@import './fonts';
@import './common';
@import './loader';
@import './scroll-attention';
@import './header';
@import './footer';
@import './home/home';
@import './contact/contact';
/*-----------------------------------*
	$style
*------------------------------------*/
body {
}
