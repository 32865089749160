@charset "UTF-8";
/*-----------------------------------*
	$Fonts
*------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?6ak16a');
  src: url('../fonts/icomoon.eot?6ak16a#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?6ak16a') format('truetype'),
    url('../fonts/icomoon.woff?6ak16a') format('woff'),
    url('../fonts/icomoon.svg?6ak16a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right2:before {
  content: '\ea3c';
}
.icon-arrow-left2:before {
  content: '\ea40';
}
.icon-circle-down:before {
  content: '\ea43';
}
