@charset "UTF-8";
/*-----------------------------------*
	$Footer
*------------------------------------*/
footer {
  // color: rgba($color_white, 0.5);
  position: relative;
  z-index: 9998;
  // height: 100vh;
  padding: 80px 0;
  text-align: center;
  font-weight: normal;
  p {
    margin: 0;
    @include fz(16, 16);
  }
}
