@charset "UTF-8";
/*-----------------------------------*
	$Header
*------------------------------------*/
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: var(--base-color);
}
