@charset "UTF-8";
/*-----------------------------------*
	$Contact
*------------------------------------*/
.home .contact {
  position: relative;
  color: var(--base-color);
  padding: 80px;
  background-color: var(--sub-color);
  border-radius: 80px;
  overflow: hidden;
  .section-header {
    transform: translateY(0) !important;
    h1 {
      &::after {
        height: 4px;
        bottom: -8px;
      }
    }
  }
  .section-body {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-top: 0;
    text-align: right;
    .btn {
    }
  }
  .logo {
    display: none;
    // justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: auto;
    height: 100%;
    svg {
      margin-right: 80px;
      width: auto;
      height: 100%;
      opacity: 0.1;
      * {
        fill: var(--base-color);
      }
    }
    animation: tickerContactTxt 20s infinite linear 0.5s both;
  }
  @include media(lg) {
    display: block;
    border-radius: 40px;
    padding: 40px 24px;
    text-align: center;
    .section-body {
      margin-top: 40px;
      justify-content: center;
      text-align: center;
    }
  }
  /*-----------------------------------*
	$Animation
  *------------------------------------*/
  // max-width: 100%;
}
@keyframes tickerContactTxt {
  0% {
    transform: translate3D(0, 0, 0);
    // left: 0;
  }
  100% {
    transform: translate3D(-50%, 0, 0);
    // left: 500px;
  }
}
