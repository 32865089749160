@charset "UTF-8";
/*-----------------------------------*
	$Common
*------------------------------------*/
::selection {
  background: var(--sub-color);
  color: var(--base-color);
}
body {
  text-align: center;
  @include fz(18, 36);
  color: var(--sub-color);
  background-color: var(--base-color);
  // font-feature-settings: 'palt';
  > main {
    position: relative;
    z-index: 10;
  }
  @include media(md) {
    @include fz(16, 32);
  }
}
ul,
li,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
figure {
  margin: 0;
}
p {
  &:first-child {
    margin-top: 0;
  }
}
a {
  color: var(--sub-color);
}
.row {
  display: flex;
  .col {
    flex: 1 0 0%;
  }
}
[class*='container'] {
  margin: 0 auto;
  max-width: 1120px;
  &.container-s {
    max-width: 960px;
  }
  @include media(xxl) {
    margin-left: 8%;
    margin-right: 8%;
    max-width: 100%;
  }
  @include media(xl) {
    &.container-s {
      max-width: 100%;
    }
  }
  @include media(md) {
    margin-left: 4%;
    margin-right: 4%;
  }
}
.section {
  text-align: left;
  margin-top: 160px;
  &.row {
    gap: get_vw(40);
  }
  @include media(md) {
    margin-top: 80px;
  }
}
.section-header {
  h1 {
    display: inline-block;
    position: relative;
    font-family: 'Zen Maru Gothic', serif;
    margin: 0;
    line-height: 1;
    &::after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background-color: currentColor;
    }
  }
  h2 {
    font-family: 'Zen Maru Gothic', serif;
    margin: 1.6rem 0 0;
    line-height: 1;
    font-weight: normal;
  }
  @include media(md) {
    h1 {
      &::after {
        height: 4px;
        border-radius: 2px;
        bottom: -8px;
      }
    }
    h2 {
      margin: 0.8rem 0 0;
    }
  }
}
.section-body {
  margin-top: 80px;
  @include media(md) {
    margin-top: 32px;
  }
}
[class^='btn'] {
  min-width: 320px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-decoration: none;
  // padding: 0 8px 0 32px;
  border-radius: 40px;
  height: 80px;
  background-color: var(--base-color);
  p {
    // font-family: 'Zen Maru Gothic', sans-serif;
    @include fz(24, 24);
    font-weight: bold;
    margin: 0 0 0 -8%;
  }
  figure {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background-color: var(--sub-color);
    overflow: hidden;
    i {
      color: var(--base-color);
      @include fz(40, 40);
      transform: translateX(-16px);
      transition: transform 0.5s $ease-out-expo;
    }
  }
  @include hover() {
    figure {
      i {
        transform: translateX(0px);
      }
    }
  }
  @include media(md) {
    width: 100%;
  }
}
.btn-back {
  color: var(--base-color);
  background-color: var(--sub-color);
  p {
    margin: 0 -8% 0 0;
  }
  figure {
    right: 0;
    left: 8px;
    background-color: var(--base-color);
    i {
      color: var(--sub-color);
      transform: translateX(16px);
    }
  }
}
/*-----------------------------------*
	$Animation
  *------------------------------------*/
.section-header.anime {
  transform: translateY(8svh);
  opacity: 0;
  transition: transform 1s $ease-out-cubic, opacity 1s $linear;
  h1 {
    &::after {
      transform: scaleX(0);
      transition: transform 0.5s $ease-out-expo;
      transform-origin: left;
    }
  }
  &.is-active {
    transform: translateY(0%);
    opacity: 1;
    h1 {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
.anime-slidein {
  transform: translateY(8svh);
  opacity: 0;
  transition: transform 1s $ease-out-cubic, opacity 1s $linear;

  &.is-active {
    transform: translateY(0%);
    opacity: 1;
  }
}
