@use 'sass:math';
/*------------------------------------*
	media query
*------------------------------------*/
$breakpoints: (
  'sm': 'screen and (max-width: 576px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 992px)',
  'xl': 'screen and (max-width: 1120px)',
  'xxl': 'screen and (max-width: 1280px)',
) !default;
@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

/*------------------------------------*
	vw
*------------------------------------*/
@function get_vw($size, $viewport: $vp_pc) {
  //@return (math.div($size,$viewport))*100vw;
  @return calc(#{$size} / #{$viewport} * (100vw - var(--scrollbar-width)));
}
/*------------------------------------*
	font size
*------------------------------------*/
@mixin fz($size: $font_size_base, $height: $size * 1.5, $base: 16) {
  font-size: $size + px;
  font-size: (math.div($size, $base)) * 1rem;
  line-height: math.div($height, $size);
}
/*------------------------------------*
	hover
  
  @include hover() {
    opacity: .9;
  }
*------------------------------------*/
@mixin hover($ie: false) {
  // &:focus {
  //   @content;
  // }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }

  @if $ie {
    @at-root .ua-ie &:hover {
      @content;
    }
  }
}
/*------------------------------------*
	Custom easing functions.
  http://easings.net/ja
*------------------------------------*/

// linear
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);

// sine
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// quad
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// cubic
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// quart
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

// quint
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// expo
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

// circ
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// back
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
