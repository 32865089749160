@charset "UTF-8";
/*-----------------------------------*
	$ScrollAttention
*------------------------------------*/
.scroll-attention {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  bottom: 4vh;
  left: calc(50% - 16px);
  width: 32px;
  height: 48px;
  border: 1px solid var(--sub-color);
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.2s $linear;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 0px;
    left: calc(50% - 4px);
    background: var(--sub-color);
    opacity: 0;
    animation: ball-move 1.6s $ease-out-sine infinite;
  }
  &.show {
    opacity: 0.5;
  }
  &::after {
    animation-delay: 0.8s;
  }
  .arrow {
    // position: relative;
    // width: 8px;
    // height: 8px;
    // opacity: 1;
    // transform: translateY(-4px) scale(0.5);
    // animation: move 1.6s $ease-out-sine infinite;
    // background-color: var(--sub-color);
    // border-radius: 4px;
    // &:nth-child(2) {
    //   animation-delay: 0.1s;
    // }
    // &:nth-child(3) {
    //   animation-delay: 0.2s;
    // }
  }
  @keyframes ball-move {
    50% {
      opacity: 1;
      // transform: scale(1);
    }
    47.5%,
    52.5% {
      transform: translateY(12px);
    }
    100% {
      transform: translateY(24px);
    }
  }
}
