@charset "UTF-8";
/*-----------------------------------*
	$Bg
*------------------------------------*/
.home .bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  // mix-blend-mode: luminosity;
  background-color: var(--base-color);
  canvas {
    // opacity: 0.5;
    // mix-blend-mode: luminosity;
  }
}
