@charset "UTF-8";
@import '../header';
/*-----------------------------------*
	$Contact
*------------------------------------*/

html.contact {
  background-color: var(--base-color);
  h1 {
    color: var(--sub-color);
    margin: 0;
  }
  h4,
  h3 {
    margin-top: 0;
  }
  main {
    text-align: center;
    color: $color_black;
    @include media(md) {
      max-width: 100%;
      padding: 4%;
    }
  }
  .contact-inner {
    text-align: center;
    max-width: 640px;
    margin: 24px auto 0;
    padding: 40px;
    background-color: var(--sub-color);
    border-radius: 40px;
    @include media(md) {
      padding: 40px 4%;
      border-radius: 24px;
    }
  }
  .block {
    text-align: left;

    &:not(&:first-child) {
      margin-top: 40px;
    }
    > label {
      line-height: 1;
      display: block;
      margin-bottom: 16px;
    }
  }
  .require {
    color: $color_white;
    margin-left: 8px;
    @include fz(12, 12);
    padding: 4px;
    border-radius: 4px;
    background-color: $color_red;
  }
  input:not([type='radio']),
  button,
  select,
  textarea {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $color_light-gray;
    padding: 16px 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  input[type='radio'] {
    & + label {
      display: inline;
      margin-right: 16px;
    }
  }
  input[type='submit'],
  input[type='button'] {
    @include fz(24, 24);

    display: inline-block;
    cursor: pointer;
    color: $color_white;
    margin-top: 24px;
    background: var(--base-color);
    max-width: 320px;
    height: 80px;
    border-radius: 40px;
  }
  select {
    color: var(--base-color);
  }
  .select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      // pointer-events: none;
      position: absolute;
      // top: 8px;
      right: 16px;
      font-family: 'icomoon' !important;
      content: '\ea43';
      opacity: 0.4;
    }
  }
  .btn-back {
    margin-top: 40px;
  }
  .value {
    background-color: $color_light-gray;
    padding: 16px 16px;

    border-radius: 0.25rem;
  }
  .error_messe {
    color: $color_red;
  }
}
