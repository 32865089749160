@charset "UTF-8";
/*-----------------------------------*
	$Hero
*------------------------------------*/
.home .hero {
  height: 100vh;
  display: flex;
  align-items: center;
  @include media(md) {
    height: auto;
    display: block;
  }
  .hane {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
    height: 100vh;
    gap: 8vh;
    .line {
      height: 14vh;
      background-color: var(--sub-color);
      border-top-right-radius: 8vh;
      border-bottom-right-radius: 8vh;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 90%;
      }
      &:nth-child(3) {
        width: 80%;
      }
      &:nth-child(4) {
        width: 70%;
      }
    }
    @include media(md) {
      width: 70%;
      height: 50svh;
      gap: 3vh;
      .line {
        height: 7vh;
      }
    }
  }
  .txts {
    position: relative;
    color: var(--sub-color);
    width: 60%;
    height: 82vh;
    display: flex;
    padding: 0 get_vw(40) 0 0;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    h2 {
      margin: 0;
      font-size: get_vw(80);
      // @include fz(80, 80);
      line-height: 1;
      mix-blend-mode: color;
    }
    h3 {
      font-size: get_vw(32);
      margin: get_vw(32) 0 0;
      line-height: 1;
    }
    .logo {
      width: get_vw(240);
      margin-left: auto;
      svg {
        width: 100%;
        height: 100%;
        * {
          fill: currentColor;
        }
      }
    }
    @include media(md) {
      width: 100%;
      height: 50svh;
      text-align: left;
      // margin-top: get_vw(-40, $vp_sp);
      padding: 0 0 0 4%;
      justify-content: flex-start;
      h2 {
        // margin-top: get_vw(-160, $vp_sp);
        font-size: get_vw(48, $vp_sp);
        line-height: 1.2;
      }
      h3 {
        font-size: get_vw(16, $vp_sp);
        line-height: 1;
      }
      .logo {
        // position: absolute;
        // bottom: get_vw(24, $vp_sp);
        width: get_vw(80, $vp_sp);
        margin: get_vw(40, $vp_sp) 0 0 0;
        // margin-top: get_vw(40, $vp_sp);
      }
    }
  }

  /*-----------------------------------*
	$Animation
  *------------------------------------*/
  .hane {
    .line.anime {
      transform: translateX(-100%);
      transition: transform 1s $ease-out-expo;
      &.is-active {
        transform: translateX(0);
      }
    }
  }
  .txts {
    h2.anime,
    .logo.anime {
      opacity: 0;
      transition: opacity 1s $linear;
      &.is-active {
        opacity: 1;
      }
    }
  }
}
