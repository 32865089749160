@charset "UTF-8";
/*-----------------------------------*
	$Header
*------------------------------------*/
#header {
  position: relative;
  z-index: 9999;
  padding: 40px;
  figure {
    width: 120px;
    img {
      width: 100%;
      height: auto;
    }
  }
  @include media(md) {
    padding: 4%;
  }
}
