@charset "UTF-8";
/*-----------------------------------*
	$Company
*------------------------------------*/
.home .company {
  .section-header {
    text-align: center;
  }
  dl {
    line-height: 1.5;
    padding: 0.8rem 0;
    &:not(:first-child) {
      border-top: 1px solid rgba($color_white, 0.2);
    }
    dt {
    }
    dd {
      flex-grow: 4;
    }
  }
}
