@charset "UTF-8";
/*-----------------------------------*
	$Service
*------------------------------------*/
.home .service {
  // overflow: hidden;
  padding: 0 0 0 4%;
  @include media(md) {
    padding: 0 0 0 4%;
  }
  .section-body {
    gap: 4%;
    @include media(md) {
      display: block;
    }
  }
  .section-body > .col:nth-child(1) {
    @include media(md) {
      margin-right: 4%;
    }
  }
  .col:nth-child(2) {
    height: 100%;
    position: sticky;
    top: 16px;
  }
  .service-content {
    height: 100%;
    position: sticky;
    top: 0;
    overflow: hidden;
    .item {
      white-space: nowrap;
      align-items: center;
      width: 110%;
      height: 80px;
      padding: 0 8px;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      background-color: var(--sub-color);
      &:not(:first-child) {
        margin-top: 16px;
      }
      &:nth-child(2) {
        margin-left: 24px;
      }
      &:nth-child(3) {
        margin-left: 48px;
      }
      &:nth-child(4) {
        margin-left: 72px;
      }
      .item__figure {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--base-color);
        max-width: 64px;
        height: 64px;
        border-radius: 32px;
        img {
          width: 48px;
        }
      }
      .item__texts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--base-color);
        margin-left: 8px;
        h2,
        h3 {
          margin: 0;
          line-height: 1;
        }
        h2 {
        }
        h3 {
          font-weight: normal;
          margin-top: 0.4rem;
        }
      }
      @include media(md) {
        height: get_vw(48, $vp_sp);
        padding: 0 get_vw(4, $vp_sp);
        border-top-left-radius: get_vw(28, $vp_sp);
        border-bottom-left-radius: get_vw(28, $vp_sp);
        &:not(:first-child) {
          margin-top: get_vw(8, $vp_sp);
        }
        &:nth-child(2) {
          margin-left: get_vw(8, $vp_sp);
        }
        &:nth-child(3) {
          margin-left: get_vw(24, $vp_sp);
        }
        &:nth-child(4) {
          margin-left: get_vw(40, $vp_sp);
        }
        .item__figure {
          max-width: get_vw(40, $vp_sp);
          height: get_vw(40, $vp_sp);
          border-radius: get_vw(20, $vp_sp);
          img {
            width: get_vw(24, $vp_sp);
          }
        }
        .item__texts {
          // margin-left: get_vw(16, $vp_sp);
          h2 {
            font-size: get_vw(20, $vp_sp);
          }
          h3 {
            font-size: get_vw(12, $vp_sp);
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
}
