@charset "UTF-8";
/*-----------------------------------*
	$Setting
*------------------------------------*/
$vp_pc: 1280;
$vp_sp: 375;
$color_blue: #1a96d5;
$color_white: #fff;
$color_black: #212121;
$color_red: #cc0000;
$color_light-gray: #f7f7f7;
html {
  --scrollbar-width: 0px;
  --base-color: #1a96d5;
  --sub-color: #fff;
}

.zen-maru-gothic-regular {
  font-family: 'Zen Maru Gothic', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.zen-maru-gothic-bold {
  font-family: 'Zen Maru Gothic', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@for $i from 1 through 9 {
  .noto-sans-jp-#{$i*100} {
    font-family: 'Noto Sans JP', sans-serif;
    font-optical-sizing: auto;
    font-weight: $i * 100;
    font-style: normal;
  }
}
h1,
.h1 {
  @include fz(80, 120);
  @include media(md) {
    @include fz(40, 60);
  }
}
h2,
.h2 {
  @include fz(40, 60);
  @include media(md) {
    @include fz(32, 48);
  }
}
h3,
.h3 {
  @include fz(32, 48);
  @include media(md) {
    @include fz(24, 36);
  }
}
h4,
.h4 {
  @include fz(24, 36);
  @include media(md) {
    @include fz(16, 24);
  }
}
h5,
.h5 {
  @include fz(16, 16);
  @include media(md) {
    @include fz(14, 21);
  }
}
